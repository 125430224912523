import { AdjustmentFilter } from '@pixi/filter-adjustment';
import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import {
  FeatureState,
  GameMode,
  ReelId,
  bonusIds,
  buyFeatureBonusesId,
  freeRoundBonusId,
  reelSets,
} from '../../global.d';
import AnimationChain from '../../slotMachine/animations/animationChain';
import AnimationGroup from '../../slotMachine/animations/animationGroup';
import { TweenProperties } from '../../slotMachine/animations/d';
import Tween from '../../slotMachine/animations/tween';
import {
  INDICATOR_MULTIPLIER_IN_DURATION,
  INDICATOR_MULTIPLIER_IN_SCALE_END,
  INDICATOR_MULTIPLIER_IN_SCALE_START,
  INDICATOR_MULTIPLIER_OUT_DURATION,
  INDICATOR_MULTIPLIER_OUT_SCALE_END,
  INDICATOR_MULTIPLIER_OUT_SCALE_START,
} from '../../slotMachine/config';
import { easeOutBaseQuint } from '../utils';

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};

export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.FREE_SPIN_LA_TOMATINA ||
    mode === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO ||
    mode === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN
  );
};
export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE_LA_TOMATINA || mode === GameMode.BUY_FEATURE_TOMATO_SPIN;
};

export const isFreeRoundBonus = (bonusId: string): boolean => {
  return bonusId === freeRoundBonusId;
};

export const isScatter = (slotId: SlotId): boolean => {
  return slotId === SlotId.SC1;
};

export const isWild = (slotId: SlotId): boolean => {
  return slotId === SlotId.WL;
};

export const isWay = (slotId: SlotId): number => {
  if (slotId === SlotId.O) return 0;
  if (slotId === SlotId.P) return 1;
  if (slotId === SlotId.Q) return 2;
  if (slotId === SlotId.R) return 3;
  return -1;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  if (bonusIds[GameMode.FREE_SPIN_LA_TOMATINA] === bonusId) return GameMode.FREE_SPIN_LA_TOMATINA;
  if (bonusIds[GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO] === bonusId) return GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO;
  if (bonusIds[GameMode.FREE_SPIN_TOMATO_SPIN_SPIN] === bonusId) return GameMode.FREE_SPIN_TOMATO_SPIN_SPIN;
  if (bonusIds[GameMode.BUY_FEATURE_LA_TOMATINA] === bonusId) return GameMode.FREE_SPIN_LA_TOMATINA;
  if (bonusIds[GameMode.BUY_FEATURE_TOMATO_SPIN] === bonusId) return GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO;
  return GameMode.REGULAR;
};

export const isBaseReelSet = (reelSetId: string): boolean => {
  return (
    ReelId.REGULAR === reelSetId ||
    ReelId.REGULAR2 === reelSetId ||
    ReelId.REGULAR3 === reelSetId ||
    ReelId.REGULAR4 === reelSetId ||
    ReelId.REGULAR5 === reelSetId ||
    ReelId.REGULAR6 === reelSetId
  );
};

export const isBuyFeatureBonusId = (bonusId: string): boolean => {
  return (
    buyFeatureBonusesId[FeatureState.LA_TOMATINA] === bonusId ||
    buyFeatureBonusesId[FeatureState.TOMATO_SPIN] === bonusId
  );
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }
  return GameMode.REGULAR;
};

export const filterBuyMouseOver = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 1, red: 2, green: 2 });
};

export const filterBuyMouseDown = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 2, red: 2, green: 2 });
};

export const filterBuyMouseActive = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 1.5, red: 2, green: 2 });
};

export const filterMouseOver = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 1.5, red: 2, green: 2 });
};

export const filterMouseDown = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 1.5, red: 1.2, green: 1, blue: 2 });
};

export const filterDisable = (): AdjustmentFilter => {
  return new AdjustmentFilter({ contrast: 0.7, brightness: 0.3, red: 1.1, green: 1, blue: 1.4 });
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState as FeatureState]!;
};

export const getBonusMode = (bonusId: string) => {
  return bonusId === buyFeatureBonusesId[FeatureState.LA_TOMATINA]
    ? GameMode.FREE_SPIN_LA_TOMATINA
    : GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO;
};

export const textExpansion = (targetObject: PIXI.Text) => {
  const animationChain = new AnimationChain();
  const textInAnimationGroup = new AnimationGroup({});
  const textWaitAnimationGroup = new AnimationGroup({});
  const textOutAnimationGroup = new AnimationGroup({});
  const textInX = new Tween({
    object: targetObject.scale,
    target: INDICATOR_MULTIPLIER_IN_SCALE_END,
    property: TweenProperties.X,
    propertyBeginValue: INDICATOR_MULTIPLIER_IN_SCALE_START,
    duration: INDICATOR_MULTIPLIER_IN_DURATION,
    easing: easeOutBaseQuint,
  });
  const textInY = new Tween({
    object: targetObject.scale,
    target: INDICATOR_MULTIPLIER_IN_SCALE_END,
    property: TweenProperties.Y,
    propertyBeginValue: INDICATOR_MULTIPLIER_IN_SCALE_START,
    duration: INDICATOR_MULTIPLIER_IN_DURATION,
    easing: easeOutBaseQuint,
  });
  const textOutX = new Tween({
    object: targetObject.scale,
    target: INDICATOR_MULTIPLIER_OUT_SCALE_END,
    property: TweenProperties.X,
    propertyBeginValue: INDICATOR_MULTIPLIER_OUT_SCALE_START,
    duration: INDICATOR_MULTIPLIER_OUT_DURATION,
    easing: easeOutBaseQuint,
  });
  const textOutY = new Tween({
    object: targetObject.scale,
    target: INDICATOR_MULTIPLIER_OUT_SCALE_END,
    property: TweenProperties.Y,
    propertyBeginValue: INDICATOR_MULTIPLIER_OUT_SCALE_START,
    duration: INDICATOR_MULTIPLIER_OUT_DURATION,
    easing: easeOutBaseQuint,
  });
  textInAnimationGroup.addAnimation(textInX);
  textInAnimationGroup.addAnimation(textInY);
  textOutAnimationGroup.addAnimation(textOutX);
  textOutAnimationGroup.addAnimation(textOutY);
  animationChain.appendAnimation(textInAnimationGroup);
  animationChain.appendAnimation(textWaitAnimationGroup);
  animationChain.appendAnimation(textOutAnimationGroup);

  animationChain.start();
};
