import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import {
  ISongs,
  MAPPED_SYMBOLS,
  MAPPED_SYMBOLS_ANIMATIONS,
  MAPPED_SYMBOLS_STOP_ANIMATIONS,
  SlotId,
} from '../../config';
import { Arrow, EventTypes, GameMode, ISettledBet } from '../../global.d';
import { setGameMode, setIsAnticipation, setMultiplier, setNextResult } from '../../gql/cache';
import { destroySpine, isScatter, isWay, isWild } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  DEFAULT_SPRITE_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';

import { BASE_SLOT_SPINE_ANIMATE_PRIORITY, STOP_SPECIAL_SYMBOL_PRIORITY, WILD_SPINE_ANIMATE_PRIORITY } from './config';
import { SpineAnimateSlot } from './spineAnimateSlot';

class SlotsAnimationContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSymbols: SpineAnimateSlot[] = [];

  public animation?: AnimationChain | undefined;

  private simbolSpineContainer: Spine[] = [];

  private simbolSpriteContainer: PIXI.Sprite[] = [];

  private isSpinResult = false;

  private directionArray: Arrow[] = [Arrow.RIGHT, Arrow.LEFT, Arrow.UP, Arrow.DOWN];

  constructor() {
    super();
    this.sortableChildren = true;

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT * REELS_AMOUNT; i++) {
      this.simbolSpineContainer[i] = new Spine(
        PIXI.Loader.shared.resources[MAPPED_SYMBOLS_ANIMATIONS[SlotId.WL].src!]!.spineData!,
      );
      this.simbolSpriteContainer[i] = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[SlotId.WL]));
    }

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.addListener(EventTypes.START_GET_AMOUNT_WIN, this.skipStopSymbolAnimations.bind(this));
    eventManager.on(EventTypes.SETUP_REEL_POSITIONS, this.onSetupBetResult.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SPIN_END, this.slotSpritesColorReset.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showStopSlotsDisplay.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.PLAY_ANNOUNCE, this.startAnnounceAnimation.bind(this));
    eventManager.addListener(EventTypes.PLAY_ANNOUNCE_LOOP, this.startAnnounceAnimationLoop.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));
  }

  private clearSymbols(): void {
    this.slotSymbols.forEach((symbol) => {
      symbol.skip();
    });
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private initSymbols(spinResult: Icon[]): void {
    this.clearSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = new SpineAnimateSlot(
          spinResult[i * REELS_AMOUNT + j]!.id,
          j * SLOTS_PER_REEL_AMOUNT + i,
          this.simbolSpineContainer![i * REELS_AMOUNT + j]!,
          this.simbolSpriteContainer![i * REELS_AMOUNT + j]!,
        );
        symbol.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private changeMode(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private showStopSlotsDisplay(spinResult: Icon[]) {
    this.animation?.skip();
    this.animation = undefined;
    this.initSymbols(spinResult!);
    this.slotSymbols.forEach((symbol) => (symbol.visible = true));
  }

  private onStartSpin() {
    this.slotSymbols.forEach((symbol) => {
      this.removeChild(symbol);
    });
    this.isSpinResult = false;
    this.skipStopSymbolAnimations();
  }

  private onAnticipationStart(): void {
    this.slotSymbols.forEach((slot) => {
      if (isScatter(slot.slotId)) {
        slot.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        slot.setTint(ANTICIPATION_SLOTS_TINT);
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.setTint(DEFAULT_SPRITE_COLOR);
    });
  }

  private onSetupBetResult(): void {
    if (this.isSpinResult) return;

    this.initSymbols(setNextResult()!.bet.result.spinResult);
    this.isSpinResult = true;
  }

  private onReelStopped(reelId: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const symbol = this.slotSymbols[i * REELS_AMOUNT + reelId!]!;
      symbol.visible = true;
      symbol.startStopAnimation();
    }

    this.startOnSymbolsStopAnimations(reelId);
  }

  private startAnnounceAnimation(slotId: SlotId, position: { x: number; y: number }): void {
    if (isScatter(slotId) || isWild(slotId) || isWay(slotId) >= 0) {
      const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId!];
      if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
      const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src]!.spineData);
      const dummy = Tween.createDelayAnimation(1000);
      dummy.addOnStart(() => {
        animation.spine.x = position.x;
        animation.spine.y = position.y;
        this.addChild(animation.getSpine());
        animation.getSpine().zIndex = STOP_SPECIAL_SYMBOL_PRIORITY;
        animation.setAnimation(animationData.animation!, false);
      });
      dummy.addOnComplete(() => {
        destroySpine(animation);
        setIsAnticipation(false);
        this.removeChild(animation.spine);
      });
      dummy.addOnSkip(() => {
        destroySpine(animation);
        setIsAnticipation(false);
        this.removeChild(animation.spine);
      });
      this.stopSymbolAnimations.push(dummy);
      dummy.start();
    }
  }

  private startAnnounceAnimationLoop(slotId: SlotId, position: { x: number; y: number }): void {
    if (isWild(slotId) || isWay(slotId) >= 0) {
      const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId!];
      if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
      const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src]!.spineData);
      animation.spine.x = position.x;
      animation.spine.y = position.y;
      this.addChild(animation.getSpine());
      animation.getSpine().zIndex = STOP_SPECIAL_SYMBOL_PRIORITY;
      animation.setAnimation(animationData.animation!, true);
      eventManager.once(EventTypes.START_SPIN_ANIMATION, () => {
        destroySpine(animation);
        setIsAnticipation(false);
        this.removeChild(animation.spine);
      });
      this.stopSymbolAnimations.push(animation);
    }
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    if (reelId === 0) this.stopSymbolAnimations = [];
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slotId = setNextResult()?.bet.result.spinResult[i * REELS_AMOUNT + reelId]!.id!;

      if (
        (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId] && (isScatter(slotId) || isWild(slotId))) ||
        isWay(slotId) >= 0
      ) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId!];
        if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src]!.spineData);
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
            AudioApi.play({ type: ISongs.TSF_symbols, stopPrev: true });
          }
          animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * i;
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;
          this.addChild(animation.getSpine());
          animation.getSpine().zIndex = STOP_SPECIAL_SYMBOL_PRIORITY;
          animation.setAnimation(animationData.animation!, false);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = false;
        });
        dummy.addOnComplete(() => {
          destroySpine(animation);
          if (reelId === 1) {
            setIsAnticipation(false);
          }
          this.removeChild(animation.spine);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = true;
        });
        dummy.addOnSkip(() => {
          destroySpine(animation);
          setIsAnticipation(false);
          this.removeChild(animation.spine);
          this.slotSymbols[i * REELS_AMOUNT + reelId]!.visible = true;
        });
        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private onStartWinAnimation(nextResult: ISettledBet, _isTurboSpin: boolean) {
    this.showWin(nextResult);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.slotSymbols.forEach((symbol) => symbol.skip());
    this.slotSpritesColorReset();
  }

  private showWin(nextResult: ISettledBet): void {
    const { paylines } = nextResult;
    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) return;

    this.animation = new AnimationChain();
    {
      const wayHighlight = this.createFourWayChainAnimation(paylines);
      this.animation.appendAnimation(wayHighlight);
    }
    {
      const eachSlotsHighlight = this.createHighlightChainAnimation(paylines, true);
      this.animation.appendAnimation(eachSlotsHighlight);
    }
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((position) => {
      animationGroup.addAnimation(this.slotSymbols[position]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createFourWayChainAnimation(paylines: IWinLine[]): Animation {
    const animationChain = new AnimationChain({ isLoop: false });
    const set: Set<number>[] = [];
    const arrowCheck = [false, false, false, false];

    this.directionArray = [Arrow.NON, Arrow.NON, Arrow.NON, Arrow.NON];

    for (let i = 0; i < 4; i++) {
      set[i] = new Set<number>();
    }

    paylines.forEach((payLine) => {
      if (payLine.lineId != null) {
        if (payLine.lineId < 50) {
          payLine.winPositions.forEach((position) => {
            set[0]!.add(position);
          });
        } else if (payLine.lineId < 100) {
          payLine.winPositions.forEach((position) => {
            set[1]!.add(position);
          });
        } else if (payLine.lineId < 150) {
          payLine.winPositions.forEach((position) => {
            set[2]!.add(position);
          });
        } else {
          payLine.winPositions.forEach((position) => {
            set[3]!.add(position);
          });
        }
      }
    });

    setMultiplier().forEach((val, index) => {
      if (val > 0) {
        arrowCheck[index] = true;
      }
    });

    for (let i = 0; i < 4; i++) {
      const arrowNum = arrowCheck.filter((val, index) => val === true && index === i);
      if (arrowNum.length > 0) {
        for (let x = 0; x < 4; x++) {
          if (this.directionArray[x] === Arrow.NON) {
            this.directionArray[x] = i;
            break;
          }
        }
      }

      const chain = this.highlightSlots(Array.from(set[i]!));
      chain.addOnStart(() => {
        this.setWinSlotTint(Array.from(set[i]!));
        if (set[i!]?.size! > 0) {
          eventManager.emit(EventTypes.PAYLINE_EFFECT, this.directionArray[i]!);
        }
      });
      animationChain.appendAnimation(chain);
    }

    return animationChain;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    const arrowCheck = [false, false, false, false];
    setMultiplier().forEach((val, index) => {
      if (val > 0) {
        arrowCheck[index] = true;
      }
    });

    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions);
      chain.addOnStart(() => {
        if (payline.lineId != null) {
          this.setWinSlotTint(payline.winPositions);
          if (payline.lineId < 50) {
            if (this.directionArray[0] != Arrow.NON)
              eventManager.emit(EventTypes.PAYLINE_EFFECT, this.directionArray[0]!);
          } else if (payline.lineId < 100) {
            if (this.directionArray[1] != Arrow.NON)
              eventManager.emit(EventTypes.PAYLINE_EFFECT, this.directionArray[1]!);
          } else if (payline.lineId < 150) {
            if (this.directionArray[2] != Arrow.NON)
              eventManager.emit(EventTypes.PAYLINE_EFFECT, this.directionArray[2]!);
          } else {
            if (this.directionArray[3] != Arrow.NON)
              eventManager.emit(EventTypes.PAYLINE_EFFECT, this.directionArray[3]!);
          }
        }
      });

      chain.addOnComplete(() => {
        if (payline.lineId != null) {
          this.slotSpritesColorChange();
        }
      });

      animationChain.appendAnimation(chain);
    });
    return animationChain;
  }

  private slotSpritesColorChange(): void {
    const ColorMatrix = new PIXI.filters.ColorMatrixFilter();
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.filters = [ColorMatrix];
      ColorMatrix.saturate(-1, false);
    }
  }

  private slotSpritesColorReset(): void {
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      this.slotSymbols[i]!.setTint(DEFAULT_SPRITE_COLOR);
      this.slotSymbols[i]!.filters = null;
      this.slotSymbols[i]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
    }
  }

  private setWinSlotTint(slotPositions: number[]): void {
    this.slotSymbols.forEach((slot) => {
      const ColorMatrix = new PIXI.filters.ColorMatrixFilter();
      slot.filters = [ColorMatrix];
      ColorMatrix.saturate(-0.9, false);
    });

    slotPositions.forEach((slot) => {
      this.slotSymbols[slot]!.filters = null;
      if (isScatter(this.slotSymbols[slot]!.slotId)) {
        this.slotSymbols[slot]!.zIndex = WILD_SPINE_ANIMATE_PRIORITY;
      } else {
        this.slotSymbols[slot]!.zIndex = BASE_SLOT_SPINE_ANIMATE_PRIORITY;
      }
    });
  }
}

export default SlotsAnimationContainer;
