import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes, FeatureState } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
  setTomatoAndSpins,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import {
  countCoins,
  getLaTomatinaCoinAmount,
  getTomatoSpinCoinAmount,
  normalizeCoins,
  showCurrency,
  updateTextScale,
} from '../../utils';
import { FeatureButton } from '../components/FeatureButton';
import { SpriteButton } from '../components/SpriteButton';
import {
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y,
  FEATURE_POPUP_BB_BTN_POS_X,
  FEATURE_POPUP_BB_BTN_POS_Y,
  FEATURE_POPUP_BB_COST_POS_X,
  FEATURE_POPUP_BB_COST_POS_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_HBB_BTN_POS_X,
  FEATURE_POPUP_HBB_BTN_POS_Y,
  FEATURE_POPUP_HBB_COST_POS_X,
  FEATURE_POPUP_HBB_COST_POS_Y,
  FEATURE_POPUP_HEIGHT,
  FEATURE_POPUP_INPUT_HEIGHT,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_INPUT_WIDTH,
  FEATURE_POPUP_MINUS_BTN_HEIGHT,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_WIDTH,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_HEIGHT,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_WIDTH,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_WIDTH,
  eventManager,
} from '../config';

import { BUY_FEATURE_COST_TEXT_STYLE, BtnType } from '.';
import {
  amountTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountDisableStyle,
  totalCostTextAmountStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: PIXI.Text;

  private amountText: PIXI.Text;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: PIXI.Text;

  private btlLaTomatinaBtn: FeatureButton;

  private btlTomatoSpinBtn: FeatureButton;

  private normalCostTextAmount: PIXI.Text;

  private maxCostTextAmount: PIXI.Text;

  private ltSprite: PIXI.Sprite;

  private tssSprite: PIXI.Sprite;

  private featureState: FeatureState = FeatureState.NORMAL;

  isNoFunds: boolean;

  balance: number;

  private colorMatrixLt = new PIXI.filters.ColorMatrixFilter();
  private colorMatrixTss = new PIXI.filters.ColorMatrixFilter();

  constructor(_lines: number[]) {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig().lineSet.coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.colorMatrixLt.saturate(0, false);
    this.colorMatrixTss.saturate(-0.9, false);
    this.ltSprite = this.initLt();
    this.tssSprite = this.initTss();

    this.betAmount = this.getBetAmount(setBetAmount());

    this.btlLaTomatinaBtn = this.initTomatinaModeBtn();
    this.btlTomatoSpinBtn = this.initMaxModeBtn();
    this.normalCostTextAmount = this.initNormalCostTextAmount();
    this.maxCostTextAmount = this.initMaxCostTextAmount();

    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance?: { currency: string; amount: number }) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, () => {
      this.handleDisable();
      this.visible = true;
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.visible = false;
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.amountText);
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);

    this.addChild(this.btlLaTomatinaBtn);
    this.addChild(this.btlTomatoSpinBtn);
    this.addChild(this.normalCostTextAmount);
    this.addChild(this.maxCostTextAmount);
    this.addChild(this.ltSprite);
    this.addChild(this.tssSprite);
  }

  private initTomatinaModeBtn(): FeatureButton {
    const normalBtn = new FeatureButton({
      texture: Texture.from(ResourceTypes.buyLtfBtn),
      onClick: this.handleLaTomatinaBtnClick.bind(this),
      isActive: true,
    });
    normalBtn.y = FEATURE_POPUP_BB_BTN_POS_Y;
    normalBtn.x = FEATURE_POPUP_BB_BTN_POS_X;
    normalBtn.anchor.x = 0.5;

    return normalBtn;
  }

  private initMaxModeBtn(): FeatureButton {
    const maxBtn = new FeatureButton({
      texture: Texture.from(ResourceTypes.buyTsfBtn),
      onClick: this.handleTomatoSpinBtnClick.bind(this),
    });
    maxBtn.y = FEATURE_POPUP_HBB_BTN_POS_Y;
    maxBtn.x = FEATURE_POPUP_HBB_BTN_POS_X;
    maxBtn.anchor.x = 0.5;

    return maxBtn;
  }

  private initLt(): PIXI.Sprite {
    const ltSprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.lt));
    ltSprite.anchor.set(0.5, 0.5);
    ltSprite.position.x = FEATURE_POPUP_BB_BTN_POS_X;
    ltSprite.position.y = 270;
    ltSprite.filters = [this.colorMatrixLt];
    return ltSprite;
  }

  private initTss(): PIXI.Sprite {
    const tssSprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.ts));
    tssSprite.anchor.set(0.5, 0.5);
    tssSprite.position.x = FEATURE_POPUP_HBB_BTN_POS_X;
    tssSprite.position.y = 270;
    tssSprite.filters = [this.colorMatrixTss];
    return tssSprite;
  }

  private handleLaTomatinaBtnClick(): void {
    this.changeFeatureState(FeatureState.NORMAL);
    this.btnActive(BtnType.laTomatinaBtn);
    setTomatoAndSpins(false);
    this.btlTomatoSpinBtn.turnOff();
    this.btlLaTomatinaBtn.turnOn();
    this.updateBets();
  }

  private handleTomatoSpinBtnClick(): void {
    this.changeFeatureState(FeatureState.MAX);
    this.btnActive(BtnType.TomatoSpinBtn);
    setTomatoAndSpins(true);
    this.btlTomatoSpinBtn.turnOn();
    this.btlLaTomatinaBtn.turnOff();
    this.updateBets();
  }

  private btnActive(btn: BtnType) {
    if (setTomatoAndSpins() && btn == BtnType.TomatoSpinBtn) {
      return;
    } else if (!setTomatoAndSpins() && btn == BtnType.laTomatinaBtn) {
      return;
    }
    setTomatoAndSpins(!setTomatoAndSpins());

    this.normalCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[setTomatoAndSpins() ? 1 : 0]!;
    this.maxCostTextAmount.style = BUY_FEATURE_COST_TEXT_STYLE[setTomatoAndSpins() ? 0 : 1]!;

    if (setTomatoAndSpins()) {
      this.colorMatrixLt.saturate(-0.9, false);
      this.colorMatrixTss.saturate(0, false);
    } else {
      this.colorMatrixLt.saturate(0, false);
      this.colorMatrixTss.saturate(-0.9, false);
    }

    this.handleDisable();
  }

  private initNormalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getNormalTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.x = FEATURE_POPUP_BB_COST_POS_X;
    totalCostTextAmount.y = FEATURE_POPUP_BB_COST_POS_Y;
    totalCostTextAmount.anchor.set(0.5, 0.5);
    updateTextScale(totalCostTextAmount, this.btlLaTomatinaBtn.width - 20, 250);

    return totalCostTextAmount;
  }

  private initMaxCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getMaxTotalCost(), totalCostTextAmountDisableStyle);
    totalCostTextAmount.x = FEATURE_POPUP_HBB_COST_POS_X;
    totalCostTextAmount.y = FEATURE_POPUP_HBB_COST_POS_Y;
    totalCostTextAmount.anchor.set(0.5, 0.5);
    updateTextScale(totalCostTextAmount, this.btlTomatoSpinBtn.width - 20, 250);

    return totalCostTextAmount;
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.anchor.set(0.5, 0.5);
    popupBg.position.set(FEATURE_POPUP_WIDTH, FEATURE_POPUP_HEIGHT);
    return popupBg;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t('buyFeatureTitle'), buyFeatureTitleStyle);
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);
    updateTextScale(title, this.popupBg.width - 200, isMobile ? 450 : 300);
    return title;
  }

  private initAmountText(): PIXI.Text {
    const amountText = new PIXI.Text(i18n.t('buyFeatureBetPerGame'), amountTextStyle);
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y;
    amountText.x = FEATURE_POPUP_AMOUNT_TEXT_POSITION_X;
    amountText.anchor.set(0.5, 0);
    updateTextScale(amountText, 340, 250);
    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      onClick: this.handleMinus.bind(this),
    });
    minusBtn.width = FEATURE_POPUP_MINUS_BTN_WIDTH;
    minusBtn.height = FEATURE_POPUP_MINUS_BTN_HEIGHT;
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POSITION_X;
    minusBtn.anchor.set(0.5, 0);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      onClick: this.handlePlus.bind(this),
    });
    plusBtn.width = FEATURE_POPUP_PLUS_BTN_WIDTH;
    plusBtn.height = FEATURE_POPUP_PLUS_BTN_HEIGHT;
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POSITION_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POSITION_X;
    plusBtn.anchor.set(0.5, 0);

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.width = FEATURE_POPUP_INPUT_WIDTH;
    input.height = FEATURE_POPUP_INPUT_HEIGHT;
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;
    input.anchor.set(0.5, 0);

    return input;
  }

  private initBetValue(): PIXI.Text {
    const betValue = new PIXI.Text(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(countCoins({ totalAmount: this.getBetValue() })),
        showCurrency: showCurrency(this.currency),
      })}`,
      betValueStyle,
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0.5);

    updateTextScale(betValue, this.input.width - 5, 250);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      onClick: this.onCancel.bind(this),
    });
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5, 0);

    return cancelBtn;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    this.handleDisable();
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      onClick: this.handleClickOk.bind(this),
    });
    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5, 0);

    return okBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betAmount > 1 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private updateBets(): void {
    this.normalCostTextAmount.text = this.getNormalTotalCost();
    this.maxCostTextAmount.text = this.getMaxTotalCost();
    this.betValue.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;

    updateTextScale(this.normalCostTextAmount, this.btlLaTomatinaBtn.width - 20, 250);
    updateTextScale(this.maxCostTextAmount, this.btlTomatoSpinBtn.width - 20, 250);
    updateTextScale(this.betValue, this.input.width - 5, 250);
  }

  private getBetValue = (): number => {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private handleClickOk = (): void => {
    if (!this.isNoFunds) {
      let totalCost: string;
      if (setTomatoAndSpins()) {
        totalCost = this.getMaxTotalCost();
      } else {
        totalCost = this.getNormalTotalCost();
      }
      AudioApi.play({ type: ISongs.SFX_UI_General });
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, totalCost, this.betSettings.bets[this.betAmount]!);
      this.visible = false;
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }

    let conAmount: number;
    if (setTomatoAndSpins()) {
      conAmount = getTomatoSpinCoinAmount();
    } else {
      conAmount = getLaTomatinaCoinAmount();
    }

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * conAmount);

    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }

    this.cancelBtn.enable();
    this.btlLaTomatinaBtn.enable();
    this.btlTomatoSpinBtn.enable();
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private getNormalTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * getLaTomatinaCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getMaxTotalCost = (): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * getTomatoSpinCoinAmount()),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioApi.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }
}

export default BuyFeaturePopup;
